window.config = {
  URL_MAIN: process.env.REACT_APP_URL_MAIN || window.config?.URL_MAIN,
  URL_IPFS_FRONTEND:
    process.env.REACT_APP_URL_IPFS_FRONTEND || window.config?.URL_IPFS_FRONTEND,
  ENV_APP: process.env.REACT_APP_ENV_APP || window.config?.ENV_APP,
  URI_IPFS: process.env.REACT_APP_URI_IPFS || window.config?.URI_IPFS,
  NETWORK_VERSION:
    process.env.REACT_APP_NETWORK_VERSION || window.config?.NETWORK_VERSION,
  URI_THEGRAPH_WALL:
    process.env.REACT_APP_URI_THEGRAPH_WALL || window.config?.URI_THEGRAPH_WALL,
  URI_THEGRAPH_LOAN:
    process.env.REACT_APP_URI_THEGRAPH_LOAN || window.config?.URI_THEGRAPH_LOAN,
  URI_THEGRAPH_DAILY:
    process.env.REACT_APP_URI_THEGRAPH_DAILY ||
    window.config?.URI_THEGRAPH_DAILY,
  URL_AI_WS: process.env.REACT_APP_URL_AI_WS || window.config?.URL_AI_WS
};

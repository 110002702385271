import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import { uniqueId } from 'lodash';
import ChatHistory, {
  ChatHistoryItem,
  ChatHistoryKey
} from '../ChatHistory/ChatHistory';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from '../../../app/hooks';
import { setIsOpen } from '../../../features/wallGuideAI/wallGuideAISlice';
import useAiWs from '../../../Hooks/useAiWs';

export const MAXIMUM_LENGTH_HISTORY = 10;

interface Form {
  inputField: string;
}

const WallGuideAIChat = () => {
  const [assistantMessage, setAssistantMessage] = useState<string | null>(null);
  const [chatHistory, setChatHistory] = useState<ChatHistoryItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const lastElementChat = useRef(null);
  const dispatch = useAppDispatch();
  const ws = useAiWs({ setChatHistory, setAssistantMessage, setIsLoading });

  useEffect(() => {
    lastElementChat.current?.scrollIntoView({ behavior: 'smooth' });
  }, [assistantMessage]);

  const initialValue: Form = {
    inputField: ''
  };

  const submit = (value: Form, { resetForm }: { resetForm: () => void }) => {
    if (!ws) return;

    const userMessages = value.inputField;

    const chatHistoryOnlyMassages = chatHistory.filter(i =>
      [ChatHistoryKey.User, ChatHistoryKey.Assistant].includes(i.owner)
    );
    ws.send(
      JSON.stringify({
        question: userMessages,
        chat_history: chatHistoryOnlyMassages.length
          ? chatHistoryOnlyMassages
          : [[]]
      })
    );
    resetForm();
    setIsLoading(true);
    setChatHistory(prev => {
      const updatedData: ChatHistoryItem[] = [
        ...prev,
        { id: uniqueId(), owner: ChatHistoryKey.User, message: userMessages }
      ];
      if (prev.length > MAXIMUM_LENGTH_HISTORY) {
        updatedData.shift();
      }
      return updatedData;
    });
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        backgroundColor: '#E7E7E7',
        maxWidth: '100vw',
        width: '600px',
        boxShadow: '-5px 5px 18px -13px rgba(0, 0, 0, 0.25) inset;'
      }}
    >
      <IconButton
        type="button"
        aria-label="close"
        onClick={() => {
          dispatch(setIsOpen(false));
        }}
        sx={{
          position: 'absolute',
          right: 0,
          zIndex: 1
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          maxHeight: 'calc(100vh - 177px)',
          // maxHeight: '300px',
          overflow: 'auto',
          padding: '20px',
          scrollbarWidth: 'none'
        }}
      >
        <ChatHistory chatHistory={chatHistory} />
        {!!assistantMessage && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              marginTop: '1rem'
            }}
          >
            <Box
              sx={{
                background: '#FFFFFF',
                padding: '1rem',
                borderRadius: '15px 15px 15px 0',
                borderRadiusBottomRight: 'none',
                width: 'fit-content'
              }}
            >
              {assistantMessage}
            </Box>
          </Box>
        )}
        {(isLoading || assistantMessage === '') && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              marginBottom: '2rem'
            }}
          >
            <CircularProgress size={24} />
          </Box>
        )}
        <Box ref={lastElementChat} />
      </Box>
      <Formik onSubmit={submit} initialValues={initialValue}>
        {({ submitForm, resetForm, handleSubmit }) => (
          <Box sx={{ margin: '20px' }}>
            <Field
              name="inputField"
              component={TextField}
              color="secondary"
              variant="outlined"
              type="text"
              disabled={false}
              sx={{ width: '100%', background: '#F3F3F3' }}
              placeholder="What are we going to talk about?"
              onKeyDown={(e: { key: any }) => {
                if (e.key === 'Enter') {
                  submitForm();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      aria-label="send"
                      onClick={submitForm}
                    >
                      <SendIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default WallGuideAIChat;

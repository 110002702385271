import React, { ReactNode } from 'react';
import logoImg from '../../Resources/twgLogo.webp';
import s from './CustomMarkdownLink.module.scss';

interface Props {
  href?: string;
  children?: ReactNode;
}

const CustomMarkdownLink = ({ href, children }: Props) => {
  const siteDomine = 'https://thewall.global';

  let safeHref = href.includes(siteDomine)
    ? href.replace(siteDomine, '')
    : href;

  const isExternal = safeHref.startsWith('http');

  if (
    !isExternal &&
    (safeHref.includes('clusterId') || safeHref.includes('areaId'))
  ) {
    safeHref += '&search';
  }

  console.info({ href, safeHref });
  return (
    <a
      href={safeHref}
      target={isExternal ? '_blank' : '_self'}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      className={isExternal ? 'external-link' : 'internal-link'}
      style={{
        color: 'blue'
      }}
    >
      {!isExternal && <img src={logoImg} alt="logo" className={s.logo} />}
      {children}
    </a>
  );
};

export default CustomMarkdownLink;

import { useEffect } from 'react';
import { useGetDailyRevMutation } from '../app/services/apiTgDaily';
import { useGetLoanRevMutation } from '../app/services/apiTgLoan';
import { useGetWallRevMutation } from '../app/services/apiTgTheWall';

const useUpdateContainer = (): void => {
  const [updateWallRev] = useGetWallRevMutation();
  const [updateLoanRev] = useGetLoanRevMutation();
  const [updateDailyRev] = useGetDailyRevMutation();

  useEffect(() => {
    updateWallRev();
    updateLoanRev();
    updateDailyRev();
    const intervalID = setInterval(() => {
      updateWallRev();
      updateLoanRev();
      updateDailyRev();
    }, 60000);
    return () => {
      clearInterval(intervalID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useUpdateContainer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  isOpen: boolean;
} = {
  isOpen: false
};

export const wallGuideAISlice = createSlice({
  name: 'wallGuideAI',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    }
  }
});

export const { setIsOpen } = wallGuideAISlice.actions;

export default wallGuideAISlice.reducer;

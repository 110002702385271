import React from 'react';
import { store } from '../../../app/store';
import WallGuideAIChat from '../WallGuideAIChat/WallGuideAIChat';
import { useAppSelector } from '../../../app/hooks';
import { Box } from '@mui/material';

const WallGuideAI = () => {
  const { wallGuideAI } = useAppSelector(store.getState);

  return (
    <Box sx={{ display: wallGuideAI.isOpen ? 'block' : 'none' }}>
      <WallGuideAIChat />
    </Box>
  );
};

export default WallGuideAI;

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { apiTgTheWall } from './services/apiTgTheWall';
import { apiTgDaily } from './services/apiTgDaily';
import { apiTgLoan } from './services/apiTgLoan';
import wallGuideAIReducer from '../features/wallGuideAI/wallGuideAISlice';
import wallReducer from '../features/wall/wallSlice';
import loanReducer from '../features/loan/loanSlice';
import dailyReducer from '../features/daily/dailySlice';

export const store = configureStore({
  reducer: {
    wall: wallReducer,
    loan: loanReducer,
    daily: dailyReducer,
    wallGuideAI: wallGuideAIReducer,
    [apiTgTheWall.reducerPath]: apiTgTheWall.reducer,
    [apiTgDaily.reducerPath]: apiTgDaily.reducer,
    [apiTgLoan.reducerPath]: apiTgLoan.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(apiTgTheWall.middleware)
      .concat(apiTgDaily.middleware)
      .concat(apiTgLoan.middleware)
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
